define("discourse/plugins/discourse-primero-helpdesk-integration/discourse/initializers/init-helpdesk", ["exports", "discourse/lib/plugin-api", "discourse/lib/ajax"], function (_exports, _pluginApi, _ajax) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: "discourse-primero-helpdesk-integration",
    initialize() {
      (0, _pluginApi.withPluginApi)("0.1", api => {
        const siteSettings = api.container.lookup("site-settings:main");
        const {
          primero_coordinators_group: coordinatorsGroup,
          primero_helpdesk_status_url: helpdeskStatusUrl,
          primero_jira_url: jiraUrl,
          primero_jira_project_id: jiraProjectId,
          primero_jira_issue_type: jiraIssueType,
          primero_jira_custom_field_id: jiraCustomFieldId
        } = siteSettings;
        const getNewIssueUrl = location => [jiraUrl, "/secure/CreateIssueDetails!init.jspa?", [`pid=${jiraProjectId}`, `issuetype=${jiraIssueType}`, `customfield_${jiraCustomFieldId}=${location}`].join("&")].join("");
        api.modifyClass("component:topic-title", {
          didInsertElement: function () {
            console.log("stream owner", this.get("parentView.postStream.posts.0.user_id"));
            console.log("current user", this.get("currentUser.id"));
            console.log("entry", this.get("currentUser"));
            const currentUserId = this.get("currentUser.id");
            const isAuthor = this.get("parentView.postStream.posts.0.user_id") === currentUserId;
            const link = `${window.location.origin}${this.get("parentView.topic.url")}`;
            Promise.all([(0, _ajax.ajax)(`/groups/${coordinatorsGroup}/members.json`), (0, _ajax.ajax)({
              url: helpdeskStatusUrl,
              data: {
                link
              }
            })]).then(_ref => {
              let [c, r] = _ref;
              console.log(c.members, currentUserId);
              const isCoordinator = c.members.map(f => f.id).indexOf(currentUserId) !== -1;
              const statusElement = this.element.querySelector(".helpdesk-status");
              if (r.status) {
                statusElement.innerText = `Helpdesk status: ${r.status}`;
              } else {
                statusElement.innerText = `No relevant helpdesk ticket`;
                if (isAuthor || isCoordinator) {
                  this.element.querySelector("a.helpdesk-link").href = getNewIssueUrl(window.location.href);
                  this.element.querySelector("a.helpdesk-link").classList.remove("hidden");
                }
              }
              this.element.querySelector(".spinner").remove();
            });
          }
        });
      });
    }
  };
});