define("discourse/plugins/discourse-primero-helpdesk-integration/discourse/templates/connectors/topic-title/helpdesk", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="alert alert-info">
    <div class="spinner small"></div>&nbsp;
    <a target="_blank" noopener noreferrer class="btn-primary flag-topic btn btn-icon-text hidden helpdesk-link">
        <svg class="fa d-icon d-icon-flag svg-icon svg-string" xmlns="http://www.w3.org/2000/svg">
            <use xlink:href="#flag"></use>
        </svg>
        <span class="d-button-label">Create Helpdesk request</span>
    </a>
    <span class="helpdesk-status">Checking helpdesk status</span>
  </div>    
  
  */
  {
    "id": "MToK2gc7",
    "block": "[[[10,0],[14,0,\"alert alert-info\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"spinner small\"],[12],[13],[1,\" \\n  \"],[10,3],[14,\"target\",\"_blank\"],[14,\"noopener\",\"\"],[14,\"noreferrer\",\"\"],[14,0,\"btn-primary flag-topic btn btn-icon-text hidden helpdesk-link\"],[12],[1,\"\\n      \"],[10,\"svg\"],[14,0,\"fa d-icon d-icon-flag svg-icon svg-string\"],[14,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[12],[1,\"\\n          \"],[10,\"use\"],[14,\"xlink:href\",\"#flag\",\"http://www.w3.org/1999/xlink\"],[12],[13],[1,\"\\n      \"],[13],[1,\"\\n      \"],[10,1],[14,0,\"d-button-label\"],[12],[1,\"Create Helpdesk request\"],[13],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,1],[14,0,\"helpdesk-status\"],[12],[1,\"Checking helpdesk status\"],[13],[1,\"\\n\"],[13],[1,\"    \\n\"]],[],false,[]]",
    "moduleName": "discourse/plugins/discourse-primero-helpdesk-integration/discourse/templates/connectors/topic-title/helpdesk.hbs",
    "isStrictMode": false
  });
});